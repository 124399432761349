import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Color } from '@budbee/js/dist/ui';
import { css } from '@css';
import { IconSize } from '../../styles';
import imageList from '../../utils/image-list';
import { inlineStyles } from '../../utils/inline-styles';
import { Icon } from '../shared/icon';
import { View } from '../shared/view';
import { styles } from './style';
export const RadioButton = (props) => {
    const { title, icon, toggled, disabled, onPress, style, subtitle, children, alwaysShowChildren, iconSize = IconSize['2xl'], } = props;
    return (_jsxs(View, { style: [
            styles.root,
            toggled ? styles.toggled : undefined,
            disabled ? styles.disabled : undefined,
            style,
        ], children: [_jsxs("button", { className: css(styles.button), type: "button", onClick: !toggled && onPress && !disabled ? onPress : undefined, children: [_jsxs(View, { style: styles.buttonInner, children: [_jsxs(View, { style: styles.buttonUpper, children: [icon ? (_jsx(View, { style: inlineStyles({
                                            width: iconSize,
                                            height: iconSize,
                                        }), children: _jsx(Icon, { src: icon, color: Color.Budbee.Grey5, size: iconSize }) })) : null, _jsx("span", { className: css(styles.title), children: title })] }), toggled ? (_jsx("img", { src: imageList.Checkmark, alt: "", className: css(styles.checkmark) })) : null] }), subtitle && toggled ? _jsx("span", { className: css(styles.subtitle), children: subtitle }) : null] }), (toggled || (!toggled && alwaysShowChildren)) && children ? (_jsx("div", { className: css(styles.children), children: children })) : null] }));
};
