import { jsx as _jsx } from "react/jsx-runtime";
import { BoxParcelStatus, ConsignmentType } from '@budbee/js/tracking';
import { CancelledBoxHeader } from '../cancelled-box-header';
import { DeliveredBoxHeader } from '../delivered-box-header';
import { EstimatedBoxHeader } from '../estimated-box-header';
import { ParcelInTransitBoxHeader } from '../parcel-in-transit-box-header';
import { PendingReturnBoxHeader } from '../pending-return-box-header';
import { PickedupBoxHeader } from '../pickedup-box-header';
import { RecalledBoxHeader } from '../recalled-box-header';
import { RegisteredBoxHeader } from '../registered-box-header';
import { ReturnBookedBoxHeader } from '../return-booked-box-header';
import { ReturnToLockerBoxHeader } from '../return-to-locker-box-header';
import { UndeliveredBoxHeader } from '../undelivered-box-header';
export function BoxHeader({ boxOrder, authenticated, toggleIdentificationModal, needsVerification, returns, toggleReturnsModal, toggleLockerPickerModal, toggleSwitchDeliveryTypeModal, toggleSupportModal, toggleCancelBoxReturnModal, toggleExtendLockerDurationModal, toggleRebookExpiredBoxDeliveryModal, code, }) {
    const { eta, lockerAttributes, latestPickupDate, status, recall, orderLocation, canSwitchLocker, canSwitchDeliveryType, consignment, } = boxOrder;
    const { countryCode } = lockerAttributes.address;
    const { parcelsPickedUpFromMerchant, distributionCity } = orderLocation;
    const props = {
        authenticated,
        toggleSupportModal,
        toggleIdentificationModal,
        toggleReturnsModal,
        toggleLockerPickerModal,
        toggleSwitchDeliveryTypeModal,
        toggleExtendLockerDurationModal,
        toggleRebookExpiredBoxDeliveryModal,
        boxOrder,
    };
    const merchantName = boxOrder.merchant.name;
    const isReturn = (consignment === null || consignment === void 0 ? void 0 : consignment.type) === ConsignmentType.RETURN;
    const returnEnabled = boxOrder.returns.enabled;
    const { canExtendPickupTime } = boxOrder;
    if (status === BoxParcelStatus.Delivered) {
        return (_jsx(DeliveredBoxHeader, { ...props, code: code, needsVerification: needsVerification, latestPickupDate: latestPickupDate, countryCode: countryCode, canExtendPickupTime: canExtendPickupTime }));
    }
    // Only used for red originating orders
    if (status === BoxParcelStatus.Undelivered) {
        return (_jsx(UndeliveredBoxHeader, { ...props, toggleRebookExpiredBoxDeliveryModal: toggleRebookExpiredBoxDeliveryModal }));
    }
    if (consignment === null || consignment === void 0 ? void 0 : consignment.cancellation) {
        const { title, reason, allowedToRebook } = consignment.cancellation;
        return (_jsx(CancelledBoxHeader, { ...props, title: title, reason: reason, allowedToRebook: allowedToRebook, returns: returns, needsVerification: needsVerification, canSwitchDeliveryType: canSwitchDeliveryType }));
    }
    if ((status === BoxParcelStatus.NotStarted || status === BoxParcelStatus.CollectedShippingLabel) &&
        consignment &&
        isReturn) {
        const { stop } = consignment;
        const { allowedToCancelReservation } = returns;
        return (_jsx(ReturnToLockerBoxHeader, { ...props, code: code, needsVerification: needsVerification, stop: stop, allowedToCancelReservation: allowedToCancelReservation, toggleCancelBoxReturnModal: toggleCancelBoxReturnModal }));
    }
    if (status === BoxParcelStatus.Pending && isReturn) {
        const { allowedToCancelReservation } = returns;
        return (_jsx(PendingReturnBoxHeader, { ...props, allowedToCancelReservation: allowedToCancelReservation, toggleCancelBoxReturnModal: toggleCancelBoxReturnModal }));
    }
    if (isReturn) {
        return _jsx(ReturnBookedBoxHeader, { ...props, status: status, merchantName: merchantName });
    }
    if (recall.recalled) {
        return _jsx(RecalledBoxHeader, { ...props, merchantName: merchantName, recallReason: recall.reason });
    }
    if (status === BoxParcelStatus.PickedUp) {
        return _jsx(PickedupBoxHeader, { ...props, returns: returns });
    }
    if (eta) {
        return (_jsx(EstimatedBoxHeader, { ...props, eta: eta, needsVerification: needsVerification, canSwitchLocker: canSwitchLocker, canSwitchDeliveryType: canSwitchDeliveryType, status: status, parcelsPickedUpFromMerchant: parcelsPickedUpFromMerchant }));
    }
    if (parcelsPickedUpFromMerchant) {
        return (_jsx(ParcelInTransitBoxHeader, { ...props, merchantName: merchantName, distributionCity: distributionCity, needsVerification: needsVerification, canSwitchLocker: canSwitchLocker, canSwitchDeliveryType: canSwitchDeliveryType }));
    }
    return (_jsx(RegisteredBoxHeader, { ...props, merchantName: merchantName, distributionCity: distributionCity, needsVerification: needsVerification, canSwitchLocker: canSwitchLocker, canSwitchDeliveryType: canSwitchDeliveryType, returnEnabled: returnEnabled }));
}
