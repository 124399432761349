import { IdentificationProvider, ReturnType } from '@budbee/js/tracking';
import { getBrand } from './brand/get-brand';
import { BANK_ID_METHOD, BANK_ID_QR, BOX_RETURN_LOCKERS, BOX_RETURN_PARCEL_DIMENSIONS, BOX_RETURN_SUMMARY, CHANGE_LOCKER_CONFIRM, CHANGE_RETURN_PHOTO, EXTEND_LOCKER_DURATION, REBOOK_DELIVERY, REBOOK_EXPIRED_BOX_DELIVERY, RETURN_INSTRUCTIONS, RETURN_SELECT_DATE, RETURN_TYPE_SELECTION, SUMMARY, SWITCH_DELIVERY_TYPE_CONFIRM, } from './steps';
export const getIdentificationSteps = (provider, orderToken, authToken) => {
    if (provider === IdentificationProvider.BANK_ID) {
        return [
            {
                id: BANK_ID_METHOD,
                props: { orderToken, authToken },
                canCancel: true,
            },
            {
                id: BANK_ID_QR,
                props: { orderToken },
                canCancel: true,
            },
        ];
    }
    return [];
};
export const getNewDeliverySteps = () => [
    {
        id: REBOOK_DELIVERY,
        canCancel: true,
    },
];
export function getReturnSteps({ returnType, orderType, isReturn, reloadOrderType, isCancelled, isMissed, onContactUsClick, }) {
    const isCancelledReturn = isReturn && isCancelled;
    const isMissedReturn = isReturn && isMissed;
    if (!returnType && !isReturn) {
        return [
            {
                id: RETURN_TYPE_SELECTION,
                props: {
                    onContactUsClick,
                },
                canCancel: true,
            },
        ];
    }
    const boxSteps = [
        {
            id: RETURN_TYPE_SELECTION,
            props: {
                onContactUsClick,
            },
            canCancel: true,
        },
        {
            id: RETURN_INSTRUCTIONS,
            props: {
                returnType: ReturnType.BOX,
                orderType,
            },
            canCancel: true,
        },
        {
            id: BOX_RETURN_PARCEL_DIMENSIONS,
            canCancel: true,
        },
        {
            id: BOX_RETURN_LOCKERS,
            canCancel: true,
        },
        {
            id: BOX_RETURN_SUMMARY,
            props: {
                orderType,
                reloadOrderType,
            },
            canCancel: true,
        },
    ];
    if (returnType === ReturnType.BOX) {
        return isReturn && !isCancelledReturn && !isMissedReturn
            ? boxSteps.filter((s) => s.id !== RETURN_TYPE_SELECTION)
            : boxSteps;
    }
    const homeSteps = [
        {
            id: RETURN_TYPE_SELECTION,
            props: {
                onContactUsClick,
            },
            canCancel: true,
        },
        {
            id: RETURN_INSTRUCTIONS,
            props: {
                returnType: ReturnType.HOME_PICKUP,
                orderType,
            },
            canCancel: true,
        },
        {
            id: RETURN_SELECT_DATE,
            props: {
                orderType,
                reloadOrderType,
            },
            canCancel: true,
        },
    ];
    return isReturn && !isCancelledReturn && !isMissedReturn
        ? homeSteps.filter((s) => s.id !== RETURN_TYPE_SELECTION)
        : homeSteps;
}
export const getChangeReturnPhotoSteps = () => [
    {
        id: CHANGE_RETURN_PHOTO,
        canCancel: true,
    },
];
export const getConfirmChangeLockerLocation = (closeModal, reloadOrder, authToken) => {
    return [
        {
            id: CHANGE_LOCKER_CONFIRM,
            props: { closeModal, reloadOrder, authToken },
            canCancel: true,
        },
    ];
};
export const getSwitchToHomeDeliverySteps = (consumerAddress, authToken, reloadOrderType) => {
    return [
        {
            id: SWITCH_DELIVERY_TYPE_CONFIRM,
            props: { consumerAddress, authToken, reloadOrderType },
            canCancel: true,
        },
    ];
};
export const getSwitchToBoxDeliverySteps = (closeModal, authToken, reloadOrderType) => {
    return [
        {
            id: SWITCH_DELIVERY_TYPE_CONFIRM,
            props: { closeModal, authToken, reloadOrderType },
            canCancel: true,
        },
    ];
};
export const getExtendLockerDurationSteps = (latestPickupDate) => {
    return [
        {
            id: EXTEND_LOCKER_DURATION,
            canCancel: true,
            props: { latestPickupDate },
        },
    ];
};
export const getRebookExpiredBoxDeliverySteps = () => {
    return [
        {
            id: REBOOK_EXPIRED_BOX_DELIVERY,
            canCancel: true,
        },
    ];
};
export const getSummarySteps = () => {
    return [
        {
            id: SUMMARY,
            canCancel: true,
            prefaceHeader: getBrand() === 'porterbuddy',
            props: {
                showDeliveryTime: false,
            },
        },
    ];
};
