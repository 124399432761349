import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useCallback, useEffect, useState } from 'react';
import { ReturnType } from '@budbee/js/tracking';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { css } from '@css';
import * as Sentry from '@sentry/react';
import { useDispatch } from 'react-redux';
import { useI18n } from '../../../hooks/use-i18n';
import { useTrackingApi } from '../../../hooks/use-tracking-api';
import { useConsumerAddress, useToken } from '../../../state/order/hooks';
import { setReturnType } from '../../../state/returns/actions';
import { useReturnType } from '../../../state/returns/hooks';
import imageList from '../../../utils/image-list';
import { ErrorMessage } from '../../error-message';
import { RadioButton } from '../../radio-button';
import { Loader } from '../../shared/loader';
import { View } from '../../shared/view';
import { EmptyReturnOptions } from './empty-return-options';
import { styles } from './style';
export function ReturnTypeSelection({ authToken, canContinueChanged, onNext, onContactUsClick, }) {
    const trackingApi = useTrackingApi();
    const dispatch = useDispatch();
    const { t } = useI18n();
    const [isLoading, setIsLoading] = useState(true);
    const [options, setOptions] = useState([]);
    const [error, setError] = useState(false);
    const token = useToken();
    const returnType = useReturnType();
    const consumerAddress = useConsumerAddress();
    useEffect(() => canContinueChanged(!error && returnType !== null), [canContinueChanged, error, returnType]);
    const handleRadioPress = useCallback((type) => () => dispatch(setReturnType(type)), [dispatch]);
    useEffect(() => {
        if (!authToken) {
            setError(true);
            return;
        }
        trackingApi
            .getReturnOptions(token, authToken)
            .call()
            .then((response) => {
            if (response.status === ResponseStatus.COMPLETED) {
                setOptions(response.payload.returnOptions);
                const firstAvailableOption = response.payload.returnOptions.find((option) => option.availability.available);
                if (firstAvailableOption) {
                    dispatch(setReturnType(firstAvailableOption.type));
                }
            }
            else {
                setError(true);
            }
        })
            .catch((e) => {
            Sentry.captureException(e);
            setError(true);
        })
            .finally(() => setIsLoading(false));
    }, [authToken, onNext, token, trackingApi, dispatch]);
    const withValidOptions = validateOptions(options);
    if (error) {
        return _jsx(ErrorMessage, {});
    }
    if (isLoading) {
        return (_jsx("div", { className: css(styles.loader), children: _jsx(Loader, {}) }));
    }
    if (!withValidOptions) {
        return _jsx(EmptyReturnOptions, { onClick: onContactUsClick });
    }
    return (_jsxs(View, { children: [_jsx("span", { className: css(styles.title), children: t('chooseReturnType') }), _jsx("span", { className: css(styles.subtitle), children: t('returnTypeDescription') }), _jsx(View, { style: styles.options, children: options.map(({ type, availability: { reasonText, available } }) => {
                    return (_jsxs(Fragment, { children: [_jsx(RadioButton, { title: type === ReturnType.BOX ? t('boxReturn') : t('homeReturn'), icon: type === ReturnType.BOX ? imageList.Locker : imageList.House, toggled: returnType === type, disabled: !available, onPress: handleRadioPress(type), alwaysShowChildren: !available || type === ReturnType.HOME_PICKUP, subtitle: type === ReturnType.HOME_PICKUP && available
                                    ? `${t('homeReturnAddressInfo')} ${consumerAddress === null || consumerAddress === void 0 ? void 0 : consumerAddress.street}`
                                    : undefined, children: reasonText ? _jsx("span", { className: css(styles.error), children: reasonText }) : null }), _jsx("div", { className: css(styles.divider) })] }, type));
                }) })] }));
}
function validateOptions(options) {
    return options.some((o) => o.availability.available);
}
