import { CancellationAlertLevel, ConsignmentType, locale, orderSettings, ParcelStatus, } from '@budbee/js/tracking';
import { useEta } from '../state/eta/hooks';
import { useLanguage, useTimeZone } from '../state/locale/hooks';
import { useConspectus, useRoutedEta } from '../state/order/hooks';
import { useLatestPosition } from '../state/positions/hooks';
import { getBrand } from '../utils/brand/get-brand';
import { getCancelledTexts, isConsignmentCancelled } from '../utils/cancellation';
import { localizeMerchantName } from '../utils/locale';
import { useI18n } from './use-i18n';
function getReturnHeaderText(status) {
    switch (status) {
        case ParcelStatus.Collected:
            return 'order.pickedUpSuccess';
        case ParcelStatus.ReturnedToTerminal:
            return 'order.onWayToMerchant';
        case ParcelStatus.ReturnedToMerchant:
            return 'order.returnedToMerchant';
        default:
            return '';
    }
}
export function useTrackingHeaderText({ showDeliveryConfirmed, }) {
    const routedEta = useRoutedEta();
    const conspectus = useConspectus();
    const timeZone = useTimeZone();
    const language = useLanguage();
    const position = useLatestPosition();
    const eta = useEta();
    const { t, i18n } = useI18n();
    const brand = getBrand();
    // identification is a part of conspectus,
    // make sure it's loaded before continuing
    // to show the order
    if (!conspectus || !conspectus.identification) {
        return {
            topText: '',
            middleText: '',
            bottomText: '',
            badgeText: '',
        };
    }
    const getHeaderTexts = () => {
        var _a;
        const { consignment, orderLocation, driver, consumer } = conspectus;
        const isReturn = Boolean(consignment && consignment.type === ConsignmentType.RETURN);
        const currentStatus = conspectus.status.state;
        const { countryCode } = conspectus.address;
        const fullName = consumer === null || consumer === void 0 ? void 0 : consumer.name.split(' ');
        const firstName = fullName === null || fullName === void 0 ? void 0 : fullName[0];
        const headerTexts = {
            topText: '',
            middleText: t('waitingforGoods', {
                merchant: localizeMerchantName(conspectus.merchant.name, countryCode),
            }),
            bottomText: t('informDeliveryTime'),
            badgeText: '',
        };
        if (consignment) {
            headerTexts.topText = isReturn ? t('plannedReturnTime') : t('plannedDeliveryTime');
            headerTexts.bottomText =
                orderSettings.orderRequirements(t, conspectus, conspectus.identification, conspectus.consumer.name, isReturn) || '';
            headerTexts.middleText = timeZone
                ? i18n.etaText(routedEta
                    ? {
                        date: new Date(routedEta),
                        remainingStopCount: 0,
                    }
                    : null, currentStatus, isReturn, consignment, countryCode, language, timeZone)
                : null;
        }
        else if (currentStatus === ParcelStatus.NotStarted &&
            orderLocation &&
            orderLocation.parcelsPickedUpFromMerchant) {
            headerTexts.middleText = t('goodsInTransit', {
                merchant: localizeMerchantName(conspectus.merchant.name, countryCode),
            });
            headerTexts.bottomText = t('goodsInTransitToTerminal', {
                distributionCity: locale.localizeCityName(orderLocation.distributionCity, language),
            });
        }
        if (currentStatus === ParcelStatus.Collected ||
            currentStatus === ParcelStatus.OnRouteDelivery ||
            currentStatus === ParcelStatus.CollectedShippingLabel ||
            currentStatus === ParcelStatus.CrossDocked) {
            headerTexts.topText = conspectus.driver
                ? t('driversEta', {
                    driver: orderSettings.formatDriverName(conspectus.driver.name || ''),
                })
                : t('noEta');
        }
        if (isReturn &&
            (currentStatus === ParcelStatus.Collected ||
                currentStatus === ParcelStatus.ReturnedToTerminal ||
                currentStatus === ParcelStatus.ReturnedToMerchant)) {
            headerTexts.middleText = t(getReturnHeaderText(currentStatus), {
                merchant: localizeMerchantName(conspectus.merchant.name, countryCode),
            });
            headerTexts.topText = '';
            headerTexts.bottomText = t('reviewModal.addReviewOfservice');
        }
        if (conspectus.identification &&
            !isConsignmentCancelled(consignment) &&
            currentStatus !== ParcelStatus.Delivered &&
            currentStatus !== ParcelStatus.Miss) {
            if (conspectus.identification.needsVerification) {
                const merchant = localizeMerchantName(conspectus.merchant.name, countryCode);
                headerTexts.bottomText = '';
                headerTexts.topText = '';
                headerTexts.middleText = t('order.identificationRequiredBankId', {
                    merchant,
                });
            }
        }
        if (currentStatus === ParcelStatus.Delivered) {
            const merchant = localizeMerchantName(conspectus.merchant.name, countryCode);
            if (conspectus.status.outsideDoor) {
                headerTexts.middleText = t('order.deliveredOutsideDoorSuccess', {
                    merchant,
                });
            }
            else if (conspectus.status.withNeighbour) {
                headerTexts.middleText = t('order.deliveredToNeighbourSuccess', {
                    merchant,
                });
            }
            else {
                headerTexts.middleText = t('order.deliveredSuccess', {
                    merchant,
                });
            }
            headerTexts.topText = '';
            headerTexts.bottomText = t('reviewModal.addReviewOfservice');
        }
        if (currentStatus === ParcelStatus.Miss) {
            headerTexts.middleText = isReturn ? t('order.pickedUp.failed') : t('order.delivered.failed');
            headerTexts.topText = '';
            headerTexts.bottomText = '';
        }
        if (isConsignmentCancelled(consignment) || conspectus.cancellation) {
            const [middleText, bottomText] = getCancelledTexts(conspectus, isReturn, t);
            headerTexts.topText = '';
            headerTexts.middleText = middleText;
            headerTexts.bottomText = bottomText;
            if (((_a = consignment === null || consignment === void 0 ? void 0 : consignment.cancellation) === null || _a === void 0 ? void 0 : _a.level) === CancellationAlertLevel.NORMAL) {
                headerTexts.topText = t('homeDeliveryStatusTitle');
            }
        }
        if (brand === 'porterbuddy') {
            headerTexts.topText = t('delivery');
            if (isReturn) {
                headerTexts.topText = t('return');
            }
            if (driver && position && eta && !isConsignmentCancelled(consignment)) {
                const remainingStops = eta.remainingStopCount <= 10 ? String(eta.remainingStopCount) : '10+';
                headerTexts.badgeText =
                    eta.remainingStopCount > 1
                        ? t('mapTextRemainingStops', { remainingStops })
                        : t('mapTextRemainingStopSingular');
            }
            if (currentStatus === ParcelStatus.Delivered || currentStatus === ParcelStatus.Miss) {
                headerTexts.badgeText = '';
            }
            if (currentStatus === ParcelStatus.Delivered &&
                conspectus.settings.temperatureConfirmationRequired) {
                headerTexts.middleText = showDeliveryConfirmed
                    ? t('trackingHeader.temperatureDeliveryConfirmed.middleText')
                    : t('trackingHeader.temperatureConfirmationRequired.middleText', { userName: firstName });
                headerTexts.bottomText = showDeliveryConfirmed
                    ? t('trackingHeader.temperatureDeliveryConfirmed.bottomText')
                    : t('trackingHeader.temperatureConfirmationRequired.bottomText');
            }
        }
        return {
            headerTexts,
        };
    };
    const { headerTexts } = getHeaderTexts();
    return headerTexts;
}
