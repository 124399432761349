import { useSelector } from 'react-redux';
import { getParcelDimensions, getReturnLocker, getReturnParcelCount, getReturnType, } from './selectors';
export function useParcelDimensions() {
    return useSelector(getParcelDimensions);
}
export function useReturnLocker() {
    return useSelector(getReturnLocker);
}
export function useReturnType() {
    return useSelector(getReturnType);
}
export function useReturnParcelCount() {
    return useSelector(getReturnParcelCount);
}
