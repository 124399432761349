export const TIMETABLE = 'TIMETABLE';
export const DELIVERY_SETTINGS = 'DELIVERY_SETTINGS';
export const ACCESS_SETTINGS = 'ACCESS_SETTINGS';
export const PAYMENT = 'PAYMENT';
export const SUMMARY = 'SUMMARY';
export const REBOOK_DELIVERY = 'REBOOK_DELIVERY';
export const RETURN_INSTRUCTIONS = 'RETURN_INSTRUCTIONS';
export const RETURN_SELECT_DATE = 'RETURN_SELECT_DATE';
export const RETURN_UPLOAD_PHOTO = 'RETURN_UPLOAD_PHOTO';
export const CHANGE_RETURN_PHOTO = 'CHANGE_RETURN_PHOTO';
export const BANK_ID_METHOD = 'BANK_ID_METHOD';
export const BANK_ID_QR = 'BANK_ID_QR';
export const CHANGE_LOCKER_CONFIRM = 'CHANGE_LOCKER_CONFIRM';
export const SWITCH_DELIVERY_TYPE_CONFIRM = 'SWITCH_DELIVERY_TYPE_CONFIRM';
export const BOX_RETURN_PARCEL_DIMENSIONS = 'BOX_RETURN_PARCEL_DIMENSIONS';
export const BOX_RETURN_LOCKERS = 'BOX_RETURN_LOCKERS';
export const BOX_RETURN_SUMMARY = 'BOX_RETURN_SUMMARY';
export const RETURN_TYPE_SELECTION = 'RETURN_TYPE_SELECTION';
export const EXTEND_LOCKER_DURATION = 'EXTEND_LOCKER_DURATION';
export const REBOOK_EXPIRED_BOX_DELIVERY = 'REBOOK_EXPIRED_BOX_DELIVERY';
