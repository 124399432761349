import { createReducer } from '../../utils/create-reducer';
import { SET_PARCEL_DIMENSIONS, SET_RETURN_LOCKER, SET_RETURN_PARCEL_COUNT, SET_RETURN_TYPE, } from './actions';
const initState = {
    dimensions: null,
    returnLocker: null,
    returnType: null,
    returnParcelCount: 1,
};
export const returns = createReducer(initState, {
    [SET_PARCEL_DIMENSIONS]: (state = initState, action) => ({
        ...state,
        dimensions: action.payload.dimensions,
    }),
    [SET_RETURN_LOCKER]: (state = initState, action) => ({
        ...state,
        returnLocker: action.payload.locker,
    }),
    [SET_RETURN_TYPE]: (state = initState, action) => ({
        ...state,
        returnType: action.payload.returnType,
    }),
    [SET_RETURN_PARCEL_COUNT]: (state = initState, action) => ({
        ...state,
        returnParcelCount: action.payload.count,
    }),
});
