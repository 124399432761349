import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { border, BorderWidth, ButtonEffect, IconSize, paddingHorizontal, paddingVertical, } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        borderRadius: Border.Radius.sm,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minWidth: 235,
        backgroundColor: Color.White100,
    },
    toggled: {
        ...border(BorderWidth.sm, 'solid', Color.Budbee.Grey3),
    },
    disabled: {
        boxShadow: 'none',
        ...ButtonEffect.Disabled,
    },
    button: {
        borderRadius: Border.Radius.sm - 1,
        minHeight: 60,
        justifyContent: 'center',
        ...paddingVertical(Space.md),
        ...paddingHorizontal(Space.lg),
        border: 'none',
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
        width: '100%',
        color: Color.Budbee.Grey5,
        textRendering: 'optimizeLegibility',
        backgroundColor: 'transparent',
        ...ButtonEffect.Enabled,
        ':hover': {
            backgroundColor: Color.Budbee.Grey2,
        },
    },
    buttonInner: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    buttonUpper: {
        flexDirection: 'row',
    },
    title: {
        ...Typography.Budbee.Web.h4,
        marginLeft: Space.lg,
    },
    checkmark: {
        width: IconSize['2xl'],
        height: IconSize['2xl'],
    },
    subtitle: {
        ...Typography.Budbee.Web['body-md'],
        marginTop: Space.md,
        marginBottom: Space['2xs'],
        textAlign: 'left',
    },
    children: {
        ...paddingHorizontal(Space.lg),
        marginTop: Space.md,
        marginBottom: Space.lg,
    },
});
