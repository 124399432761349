import { Border, Color, Space } from '@budbee/js/ui';
import { StyleSheet } from '@css';
export const styles = StyleSheet.create({
    input: {
        porterbuddy: {
            textAlign: 'start',
            height: 32,
            backgroundColor: 'inherit',
            padding: 0,
            borderRadius: 0,
            marginTop: -Space.sm,
            borderBottom: `${Border.Width.md}px solid ${Color.Porterbuddy.AmigoPurple}`,
        },
        instabox: {
            backgroundColor: Color.White100,
            textAlign: 'start',
            paddingLeft: Space.lg,
        },
    },
});
