import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { css } from '@css';
import { useI18n } from '../../../hooks/use-i18n';
import { getBrand } from '../../../utils/brand/get-brand';
import { BrandedIcon } from '../../../utils/brand/icon';
import imageList from '../../../utils/image-list';
import { Footer } from '../footer';
import { styles } from '../style';
export const Categories = (props) => {
    const { onBack, score, currentCategories, options, onChangeCategories } = props;
    const { t } = useI18n();
    const brand = getBrand();
    const [selectedValues, setSelectedValues] = useState(currentCategories);
    const onChooseCategory = (option) => {
        const filteredList = selectedValues.filter((s) => s !== option.value);
        const valueAlreadyExists = selectedValues.find((s) => s === option.value);
        const updateList = [];
        if (brand === 'porterbuddy') {
            filteredList.forEach((o) => updateList.push(o));
        }
        if (!valueAlreadyExists) {
            updateList.push(option.value);
        }
        setSelectedValues(updateList);
    };
    return (_jsxs(_Fragment, { children: [_jsx("span", { className: css(styles.title), children: t(score > 3 ? 'reviewModal.positive.placeholder' : 'reviewModal.negative.placeholder') }), _jsx("div", { className: css(styles.optionsWrapper), children: options.map((option) => (_jsxs("button", { className: css(styles.optionContainer), onClick: () => onChooseCategory(option), type: "button", children: [_jsx("div", { className: css([
                                styles.checkbox,
                                selectedValues.find((v) => v === option.value) ? styles.selected : undefined,
                            ]), children: selectedValues.find((v) => v === option.value) && (_jsx(BrandedIcon, { src: {
                                    budbee: imageList.CheckboxSelected,
                                    instabox: imageList.InstaboxCheckmark,
                                    porterbuddy: imageList.PorterbuddyCheckmark,
                                }, color: {
                                    budbee: '',
                                }, size: 18 })) }), _jsx("span", { className: css(styles.option), children: option.label })] }, option.value))) }), _jsx(Footer, { disabled: selectedValues.length === 0, onBack: onBack, onNext: () => onChangeCategories(selectedValues) })] }));
};
