import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AccessModeType, DeliveryMethod, EstateType, NotificationMethod, } from '@budbee/js/tracking';
import { Space } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { useCountryCode } from '../../state/locale/hooks';
import { AccessModeLabels } from '../../utils/access-mode-labels';
import { getBrand } from '../../utils/brand/get-brand';
import { DeliveryMethodLabels, DeliveryNotificationLabels } from '../../utils/delivery-labels';
import { EstateTypeLabels } from '../../utils/estate-types';
import imageList from '../../utils/image-list';
import { ACCESS_SETTINGS, DELIVERY_SETTINGS } from '../../utils/steps';
import { BaseButton } from '../base-button';
import { View } from '../shared/view';
import { Summary } from '../summary';
const EstateTypeIcon = {
    [EstateType.HOUSE]: imageList.House,
    [EstateType.APARTMENT]: imageList.Apartment,
    [EstateType.APARTMENT_ACROSS_COURTYARD]: imageList.Apartment,
};
const EstateTypeIconInstabox = {
    [EstateType.HOUSE]: imageList.HouseInstabox,
    [EstateType.APARTMENT]: imageList.ApartmentInstabox,
    [EstateType.APARTMENT_ACROSS_COURTYARD]: imageList.ApartmentInstabox,
};
const AccessModeIcon = {
    [AccessModeType.INTERCOM]: imageList.Intercom,
    [AccessModeType.DOOR_CODE]: imageList.DoorCode,
    [AccessModeType.NONE]: imageList.OpenDoor,
};
const AccessModeIconInstabox = {
    [AccessModeType.INTERCOM]: imageList.IntercomInstabox,
    [AccessModeType.DOOR_CODE]: imageList.DoorCodeInstabox,
    [AccessModeType.NONE]: imageList.OpenDoorInstabox,
};
const DeliveryMethodIcon = {
    [DeliveryMethod.LEAVE_BY_DOOR]: imageList.LeaveOutsideDoor,
    [DeliveryMethod.IN_PERSON]: imageList.DoorOpen,
};
const DeliveryMethodIconInstabox = {
    [DeliveryMethod.LEAVE_BY_DOOR]: imageList.LeaveOutsideDoorInstabox,
    [DeliveryMethod.IN_PERSON]: imageList.DoorOpenInstabox,
};
const DeliveryNotificationIcon = {
    [NotificationMethod.CALL_CUSTOMER]: imageList.CallMe,
    [NotificationMethod.RING_DOOR_BELL]: imageList.doorBell,
    [NotificationMethod.KNOCK_ON_DOOR]: imageList.knockOnDoor,
    [NotificationMethod.NO_NOTIFICATION]: imageList.NoNotification,
};
const DeliveryNotificationIconInstabox = {
    [NotificationMethod.CALL_CUSTOMER]: imageList.CallMe,
    [NotificationMethod.RING_DOOR_BELL]: imageList.doorBellInstabox,
    [NotificationMethod.KNOCK_ON_DOOR]: imageList.knockOnDoorInstabox,
    [NotificationMethod.NO_NOTIFICATION]: imageList.NoNotification,
};
export const DeliveryPreferencesSummary = (props) => {
    const { estateType, accessMode, deliveryMethod, deliveryNotification, alternativeLeaveWithNeighbour, nameOnDoor, floor, instructions, neighbours = [], showPreface, } = props;
    const { t, i18n, language } = useI18n();
    const brand = getBrand();
    const isOpen = (accessMode === null || accessMode === void 0 ? void 0 : accessMode.type) === AccessModeType.NONE;
    const countryCode = useCountryCode().toLowerCase();
    const isNL = countryCode === 'nl';
    const showNotificationMethod = () => {
        const exists = Boolean(deliveryNotification);
        const isNoNotification = deliveryNotification === NotificationMethod.NO_NOTIFICATION;
        if (!exists) {
            return false;
        }
        if (isNoNotification && brand !== 'porterbuddy') {
            return false;
        }
        return true;
    };
    return (_jsxs(View, { style: styles.container, children: [_jsxs(Summary, { children: [deliveryMethod && (_jsx(Summary.Item, { title: t('deliverySettings.howToDeliver'), text: t(DeliveryMethodLabels[deliveryMethod]), icon: brand === 'instabox'
                            ? DeliveryMethodIconInstabox[deliveryMethod]
                            : DeliveryMethodIcon[deliveryMethod] })), showNotificationMethod() && deliveryNotification && (_jsx(Summary.Item, { title: t('deliverySettings.notification'), text: t(DeliveryNotificationLabels[deliveryNotification]), icon: brand === 'instabox'
                            ? DeliveryNotificationIconInstabox[deliveryNotification]
                            : DeliveryNotificationIcon[deliveryNotification] })), alternativeLeaveWithNeighbour && (_jsx(Summary.Item, { title: t('deliverySettings.leaveWithNeighbour'), text: t('deliverySettings.leaveWithNeighbourSubtitle'), icon: imageList.LeaveWithNeighbour })), _jsx(BaseButton, { variant: "dark", onClick: () => showPreface(DELIVERY_SETTINGS), label: t('change.delivery.preferences') })] }), _jsxs(Summary, { children: [estateType && (_jsx(Summary.Item, { title: t('summary.estateType'), text: t(EstateTypeLabels[estateType]), icon: brand === 'instabox' ? EstateTypeIconInstabox[estateType] : EstateTypeIcon[estateType] })), estateType !== EstateType.HOUSE ? (_jsx(Summary.Item, { title: isOpen
                            ? t('accessSettings.howToAccess')
                            : t(AccessModeLabels[(accessMode === null || accessMode === void 0 ? void 0 : accessMode.type) || AccessModeType.NONE]), text: isOpen ? t(AccessModeLabels[AccessModeType.NONE]) : (accessMode === null || accessMode === void 0 ? void 0 : accessMode.value) || '-', icon: brand === 'instabox'
                            ? AccessModeIconInstabox[(accessMode === null || accessMode === void 0 ? void 0 : accessMode.type) || AccessModeType.NONE]
                            : AccessModeIcon[(accessMode === null || accessMode === void 0 ? void 0 : accessMode.type) || AccessModeType.NONE] })) : null, neighbours.map(({ deliveryInfo }, index) => (_jsx(Summary.Item
                    // eslint-disable-next-line react/no-array-index-key
                    , { title: t('deliverySettings.neighbourAddress'), text: deliveryInfo, icon: imageList.LeaveWithNeighbour }, index))), estateType !== EstateType.HOUSE ? (_jsx(Summary.Item, { title: t('summary.floor'), text: floor ? i18n.localizeFloorNumber(floor, language) : '-', icon: brand === 'instabox' ? imageList.FloorInstabox : imageList.Floor })) : null, estateType === EstateType.APARTMENT && !isNL ? (_jsx(Summary.Item, { title: t('accessSettings.careOf.title'), text: nameOnDoor && nameOnDoor !== '' ? nameOnDoor : '-', icon: brand === 'instabox' ? imageList.NameOnDoorInstabox : imageList.NameOnDoor })) : null, _jsx(Summary.Item, { title: t('accessSettings.specialInstructions.title'), text: instructions && instructions !== '' ? instructions : '-', icon: brand === 'instabox' ? imageList.InstructionsInstabox : imageList.Instructions }), _jsx(View, { "data-test": "change-access-preferences", children: _jsx(BaseButton, { variant: "dark", onClick: () => showPreface(ACCESS_SETTINGS), label: t('change.access.preferences'), "data-test": "change-access-preferences" }) })] })] }));
};
const styles = StyleSheet.create({
    container: {
        gap: Space.sm,
    },
});
