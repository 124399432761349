import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { BoxReturnLockers } from '../../../components/steps/box-return-lockers';
import { getCurrentLockerIdentifier } from '../../../state/lockers/selectors';
import { getToken } from '../../../state/order/selectors';
import * as returnsActions from '../../../state/returns/actions';
import { getParcelDimensions, getReturnLocker } from '../../../state/returns/selectors';
import { pipe } from '../../../utils/pipe';
const mapStateToProps = (state) => ({
    token: getToken(state),
    dimensions: getParcelDimensions(state),
    returnLocker: getReturnLocker(state),
    currentLockerIdentifier: getCurrentLockerIdentifier(state),
});
const mapDispatchToProps = (dispatch) => ({
    setReturnLocker: compose(dispatch, returnsActions.setReturnLocker),
});
const decorate = pipe(connect(mapStateToProps, mapDispatchToProps));
export const BoxReturnLockersContainer = decorate(BoxReturnLockers);
