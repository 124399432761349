import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { Space } from '@budbee/js/ui';
import * as Sentry from '@sentry/react';
import { useI18n } from '../../../hooks/use-i18n';
import { useTrackingApi } from '../../../hooks/use-tracking-api';
import { refreshTrackingPage } from '../../../utils/refresh-tracking-page';
import { ErrorMessage } from '../../error-message';
import { LockerLocationCard } from '../../locker-location-card';
import { LockerMap } from '../../locker-map';
import { Loader } from '../../shared/loader';
import { Title } from '../../title';
import { styles } from './style';
export const BoxReturnSummary = (props) => {
    const { onContinue, canContinueChanged, token, authToken, dimensions, locker } = props;
    const trackingApi = useTrackingApi();
    const { t } = useI18n();
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => canContinueChanged(true), []);
    useEffect(() => {
        if (!token || !authToken || !locker || !dimensions) {
            return;
        }
        onContinue((_next, onError) => {
            setLoading(true);
            trackingApi
                .bookBoxReturn(token, authToken, locker.lockerIdentifier, dimensions)
                .call()
                .then((response) => {
                setLoading(false);
                if (response.status === ResponseStatus.COMPLETED) {
                    refreshTrackingPage();
                }
                else {
                    setShowError(true);
                }
            })
                .catch((e) => {
                Sentry.captureException(e);
                setShowError(true);
                onError();
            });
        });
    }, []);
    if (loading) {
        return _jsx(Loader, { variant: "inlineMiddle" });
    }
    if (showError || !locker) {
        return _jsx(ErrorMessage, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(Title, { title: t('bookReturnTitle'), style: styles.title }), _jsx(LockerMap, { coordinate: locker.lockerAddress.coordinate, style: { height: 150, width: '100%', marginLeft: -Space.xl * 2 } }), _jsx(LockerLocationCard, { lockerAddress: locker.lockerAddress, openHours: locker.openHours, variant: "borderless", reservableUntil: locker.reservableUntil, reservationDurationSeconds: locker.reservationDurationSeconds })] }));
};
