import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useI18n } from '../../hooks/use-i18n';
import imageList from '../../utils/image-list';
import { BaseButton } from '../base-button';
import { OrderHero } from '../order-hero';
import { View } from '../shared/view';
import { SwitchDeliveryTypeButton } from '../switch-delivery-type-button';
import { styles } from '../box-header/style';
export const RegisteredBoxHeader = ({ authenticated, merchantName, distributionCity, needsVerification, canSwitchLocker, canSwitchDeliveryType, toggleIdentificationModal, toggleSwitchDeliveryTypeModal, returnEnabled, toggleReturnsModal, }) => {
    const { t } = useI18n();
    const getBottomText = () => {
        if (returnEnabled) {
            return t('box.bookYourReturn');
        }
        if (needsVerification && canSwitchLocker) {
            return t('pinCodeChangeLockerVerification');
        }
        return t('informAboutETAUponArrivalAtDistributionTerminal', {
            distributionCity,
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(OrderHero, { topText: returnEnabled ? null : t('boxDeliveryStatusTitle'), middleText: returnEnabled
                    ? t('box.returnCreated', { merchant: merchantName })
                    : t('waitingforGoods', {
                        merchant: merchantName,
                    }), bottomText: getBottomText() }), authenticated ? (_jsxs(View, { style: styles.actionsWrapper, children: [needsVerification ? (_jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { label: t('identifyWithBankID'), iconSrc: imageList.bankId, onClick: toggleIdentificationModal, variant: "primary" }) })) : null, canSwitchDeliveryType && !needsVerification ? (_jsx(View, { style: styles.actionContainer, children: _jsx(SwitchDeliveryTypeButton, { toggleSwitchDeliveryTypeModal: toggleSwitchDeliveryTypeModal }) })) : null, returnEnabled ? (_jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { label: t('bookReturnTitle'), iconSrc: imageList.Return, onClick: toggleReturnsModal, variant: "primary" }) })) : null] })) : null] }));
};
