import { connect } from 'react-redux';
import { BoxReturnSummary } from '../../../components/steps/box-return-summary';
import { getToken } from '../../../state/order/selectors';
import { getParcelDimensions, getReturnLocker } from '../../../state/returns/selectors';
const mapStateToProps = (state) => ({
    token: getToken(state),
    dimensions: getParcelDimensions(state),
    locker: getReturnLocker(state),
});
export const BoxReturnSummaryContainer = connect(mapStateToProps)(BoxReturnSummary);
